import React from 'react'

const SVG = () => {
	
	return (
		<>
			<div class="svgcontainer relative">
				<div class="diagonal-box">
					<div class="content">&nbsp;</div>
				</div>
			</div>
		</>
	)

}

export default SVG